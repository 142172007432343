import React, { Fragment } from "react"
// import Image from "next/image"
import { RichTextItemResponse, TextRichTextItemResponse } from "@notionhq/client/build/src/api-endpoints"
import { Link } from "@remix-run/react"

// const TEMPLATE_PATH = "https://railway.app/new/template"

const RenderTextContent: React.FC<{
  isCode: boolean
  content: string
  className?: string
  children?: React.ReactNode
}> = ({ isCode, content, className }) =>
  isCode ? (
    <code className="text-pink-600">{content}</code>
  ) : (
    <span
      className={className}
      dangerouslySetInnerHTML={{ __html: content.replace("\n", "<br/>") }}
    />
  )

export const NotionText: React.FC<{ text: RichTextItemResponse[] | null, className?: string }> = ({
  text: content,
  className="",
}) => {
  const richtext = content as TextRichTextItemResponse[];
  if (richtext == null) {
    return null
  }

  return (
    <>
      {richtext.map((value, idx) => {
        const {
          annotations: { bold, code, italic, strikethrough, underline },
          text,
        } = value
        if (text == null) {
          return null
        }

        let classes = className;
        if (bold) classes += " font-semibold"
        if (italic) classes += " italic"
        if (strikethrough) classes += " line-through"
        if (underline) classes += " underline"

        return (
          <Fragment key={idx}>
            {text.link != null ? (
              <>
                {/* {_text.link.url.includes(TEMPLATE_PATH) ? (
                  <Link href={_text.link.url} className="flex justify-center">
                    <Image src="/button.svg" height={48} width={240} alt="Button"/>
                  </Link>
                ) :  */}
                (
                  <Link
                    to={text.link.url}
                    className="hover:text-green"
                  >
                    <RenderTextContent
                      isCode={code}
                      content={text.content}
                      className={classes}
                    />
                  </Link>
                )
                {/* } */}
              </>
            ) : (
              <RenderTextContent
                isCode={code}
                content={text.content}
                className={classes}
              />
            )}
          </Fragment>
        )
      })}
    </>
  )
}

export const NotionList: React.FC<{ 
  type: string, 
  children: React.ReactNode 
}> = ({ type, children }) =>
  type === "ul" ? (
    <ul className="list-disc pl-6 mb-6">{children}</ul>
  ) : (
    <ol className="list-decimal pl-6 mb-6">{children}</ol>
  )
